import React, {FC, useEffect, useState, useRef} from 'react';
import {
	Box,
	Button,
	FormControl,
	Input,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useBreakpointValue,
	useDisclosure,
} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import {MdSearch} from 'react-icons/md';
import {createSearchParams, useNavigate} from 'react-router-dom';
import LoadingModal from '../../../components/LoadingModal';
import useMe from '../../../hooks/useMe';
import {Puddleglum} from '../../../puddleglum';
import CommonProcedureSearches from './CommonProcedureSearches';
import CommonSpecialtySearches from './CommonSpecialtySearches';
import MedicalEmergencyModal from './MedicalEmergencyModal';

interface MedicalEmergencyPopupProps {}

const MedicalCareSearch: FC<MedicalEmergencyPopupProps> = () => {
	const {me} = useMe();
	const {
		isOpen: isMedicalEmergencyModalOpen,
		onOpen: onMedicalEmergencyModalOpen,
		onClose: onMedicalEmergencyModalClose,
	} = useDisclosure();
	const {
		isOpen: isLoadingModalOpen,
		onOpen: onLoadingModalOpen,
		onClose: onLoadingModalClose,
	} = useDisclosure();

	const [selectedProcedureId, setSelectedProcedureId] = useState<number>();
	const [typeOfSelectedResult, setTypeOfSelectedResult] = useState<string>();
	const [typeOfSearch, setTypeOfSearch] = useState<'treatments' | 'providers'>('providers');
	const [placeholder, setPlaceholder] = useState('');
	const [searchText, setSearchText] = useState('');

	// Providers input focus
	const [isFocusedProviders, setIsFocusedProviders] = useState(false);
	// Procedures input focus
	const [isFocusedProcedures, setIsFocusedProcedures] = useState(false);

	const navigate = useNavigate();
	const isMobile = useBreakpointValue({base: true, sm: false});

	// Refs to focus on the corresponding search button after a selection
	const providerSearchButtonRef = useRef<HTMLButtonElement>(null);
	const procedureSearchButtonRef = useRef<HTMLButtonElement>(null);

	// Autocomplete: Providers
	const {data: providerAutocompleteData} = useQuery(
		['autocomplete-providers', searchText],
		async () => {
			const reply =
				await Puddleglum.Controllers.Employee.ProviderSearchAutocompleteController.index({
					search: searchText,
				});
			return reply.data;
		},
		{
			enabled: typeOfSearch === 'providers' && searchText.length > 2,
		},
	);

	// Autocomplete: Procedures
	const {data: procedureAutocompleteData} = useQuery(
		['autocomplete-procedures', searchText],
		async () => {
			const reply =
				await Puddleglum.Controllers.Employee.ProcedureSearchAutocompleteController.index({
					search: searchText,
				});
			return reply.data;
		},
		{
			enabled: typeOfSearch === 'treatments' && searchText.length > 2,
		},
	);

	useEffect(() => {
		const providerPlaceholders = [
			'e.g., Family physician',
			'e.g., Cardiologist',
			'e.g., Pediatrician',
			'e.g., Urgent care',
		];
		const procedurePlaceholders = [
			'e.g., Lipid panel',
			'e.g., Mammogram',
			'e.g., Colonoscopy',
			'e.g., CT scan of my neck',
		];

		function updatePlaceholder() {
			if (typeOfSearch === 'providers') {
				setPlaceholder(
					providerPlaceholders[Math.floor(Math.random() * providerPlaceholders.length)],
				);
			} else {
				setPlaceholder(
					procedurePlaceholders[Math.floor(Math.random() * procedurePlaceholders.length)],
				);
			}
		}

		updatePlaceholder();
		const interval = setInterval(updatePlaceholder, 5000);
		return () => clearInterval(interval);
	}, [typeOfSearch]);

	const performSearch = () => {
		if (selectedProcedureId) {
			navigate(
				`/procedure-search-results?id=${selectedProcedureId}&type=${typeOfSelectedResult}`,
			);
		} else {
			navigate({
				pathname:
					typeOfSearch === 'treatments'
						? '/procedure-selection'
						: '/provider-search-results',
				search: createSearchParams({search: searchText}).toString(),
			});
		}
	};

	const handleSearchButtonClick = () => {
		onMedicalEmergencyModalOpen();
	};

	const handleNotEmergencyClick = async () => {
		onMedicalEmergencyModalClose();
		onLoadingModalOpen();
		performSearch();
	};

	const handleCommonSearchClick = (selection: string) => {
		setSearchText(selection);
		onMedicalEmergencyModalOpen();
	};

	if (!me || me.inactivated_at) return null;

	return (
		<Box w='full'>
			<Tabs
				size={{base: 'lg', md: 'md'}}
				variant='soft-rounded'
				colorScheme='blue'
				onChange={(index) => setTypeOfSearch(index === 0 ? 'providers' : 'treatments')}
			>
				<TabList>
					<Tab>{isMobile ? 'Providers' : 'Search for a Provider'}</Tab>
					<Tab>{isMobile ? 'Procedures' : 'Search for a Procedure'}</Tab>
				</TabList>

				<TabPanels>
					{/* Providers */}
					<TabPanel px={0} pb={0}>
						<Box position='relative'>
							<Stack direction={{base: 'column', md: 'row'}} spacing={4}>
								<FormControl>
									<Input
										autoComplete='off'
										size={{base: 'lg', md: 'md'}}
										bgColor='white'
										placeholder={placeholder}
										value={searchText}
										onFocus={() => setIsFocusedProviders(true)}
										onBlur={() => {
											setTimeout(() => setIsFocusedProviders(false), 150);
										}}
										onChange={(e) => setSearchText(e.target.value)}
									/>
									{/* Provider autocomplete */}
									{isFocusedProviders &&
										typeOfSearch === 'providers' &&
										providerAutocompleteData &&
										providerAutocompleteData.length > 0 && (
											<Box
												position='absolute'
												bg='white'
												borderWidth='1px'
												borderRadius='md'
												boxShadow='sm'
												w='100%'
												mt={1}
												zIndex={10}
											>
												{providerAutocompleteData.map((item: any) => (
													<Box
														key={item.id}
														p={2}
														_hover={{bg: 'gray.100'}}
														cursor='pointer'
														onMouseDown={() => {
															setSelectedProcedureId(undefined);
															setTypeOfSelectedResult(item.type);
															setSearchText(item.name);
															setIsFocusedProviders(false);
															providerSearchButtonRef.current?.click();
														}}
													>
														{item.name}
													</Box>
												))}
											</Box>
										)}
								</FormControl>
								<Button
									ref={providerSearchButtonRef}
									isDisabled={!searchText}
									leftIcon={<MdSearch />}
									minW='400px'
									size={{base: 'lg', md: 'md'}}
									colorScheme='blue'
									onClick={handleSearchButtonClick}
								>
									Search
								</Button>
							</Stack>
						</Box>
						<CommonSpecialtySearches mt={8} onSelectSearch={handleCommonSearchClick} />
					</TabPanel>

					{/* Procedures */}
					<TabPanel px={0} pb={0}>
						<Box position='relative'>
							<Stack direction={{base: 'column', md: 'row'}} spacing={4}>
								<FormControl>
									<Input
										autoComplete='off'
										size={{base: 'lg', md: 'md'}}
										bgColor='white'
										placeholder={placeholder}
										value={searchText}
										onFocus={() => setIsFocusedProcedures(true)}
										onBlur={() => {
											setTimeout(() => setIsFocusedProcedures(false), 150);
										}}
										onChange={(e) => setSearchText(e.target.value)}
									/>
									{/* Procedure autocomplete */}
									{isFocusedProcedures &&
										typeOfSearch === 'treatments' &&
										procedureAutocompleteData &&
										procedureAutocompleteData.length > 0 && (
											<Box
												position='absolute'
												bg='white'
												borderWidth='1px'
												borderRadius='md'
												boxShadow='sm'
												w='100%'
												mt={1}
												zIndex={10}
											>
												{procedureAutocompleteData.map((item: any) => (
													<Box
														key={item.id}
														p={2}
														_hover={{bg: 'gray.100'}}
														cursor='pointer'
														onMouseDown={() => {
															setTypeOfSelectedResult(item.type);
															setSelectedProcedureId(item.id);
															setSearchText(item.name);
															setIsFocusedProcedures(false);
															procedureSearchButtonRef.current?.click();
														}}
													>
														{item.name}
													</Box>
												))}
											</Box>
										)}
								</FormControl>
								<Button
									ref={procedureSearchButtonRef}
									leftIcon={<MdSearch />}
									colorScheme='blue'
									size={{base: 'lg', md: 'md'}}
									onClick={handleSearchButtonClick}
								>
									Search
								</Button>
							</Stack>
						</Box>
						<CommonProcedureSearches mt={8} onSelectSearch={handleCommonSearchClick} />
					</TabPanel>
				</TabPanels>
			</Tabs>

			<MedicalEmergencyModal
				isOpen={isMedicalEmergencyModalOpen}
				onConfirm={handleNotEmergencyClick}
				onClose={onMedicalEmergencyModalClose}
			/>
			<LoadingModal
				estimatedTime={4000}
				steps={[
					'Querying the AI engine...',
					'Identifying possible results...',
					'Mapping results to organizations...',
					'Loading prices...',
					'Preparing your results...',
				]}
				onClose={onLoadingModalClose}
				isOpen={isLoadingModalOpen}
			/>
		</Box>
	);
};

export default MedicalCareSearch;
